import React from 'react'
import styles from './commands.module.scss'
import { links, info } from '../config'
import { Commands, Command } from '../typings'
import ListElement from '../ListElement/ListElement'

const rawCommands: Command[] = [
	{
		name: 'help',
		icon: 'fas fa-fw fa-question-circle',
		description: 'List down all available commands',
		execute(app) {
			const { commands } = app.state
			return (
				<>
					Available commands:
					{[...commands.values()].map(
						({ icon, name, description }, key) => (
							<ListElement
								key={key}
								icon={icon}
								name={name}
								description={description}
								help
							/>
						)
					)}
				</>
			)
		},
	},
	{
		name: 'info',
		icon: 'fas fa-fw fa-info-circle',
		description: 'Show information about me',
		execute(app) {
			const { userDataLoaded, userData } = app.state
			if (!userDataLoaded)
				return <>mohamed: user data could not be fetched</>
			const { avatar_url, login, name, bio } = userData
			return (
				<div className={styles.infoWrapper}>
					<div className={styles.infoInner}>
						<img
							src={avatar_url}
							className={styles.avatar}
							alt="GitHub avatar"
						/>
						<div className={styles.content}>
							<div className={styles.header}>
								<strong>{name}</strong>{' '}
								<span className="muted">@{login}</span>
							</div>
							<em className={styles.bio}>"...{bio}"</em>
							<div className={styles.info}>{info}</div>
						</div>
					</div>

					<div className={styles.icons}>
						<i className="fab fa-fw fa-node-js"></i>
						<i className="fab fa-fw fa-laravel"></i>
						<i className="fab fa-fw fa-vuejs"></i>
						<i className="fab fa-fw fa-js"></i>
						<i className="fab fa-fw fa-php"></i>
						<i className="fab fa-fw fa-docker"></i>
					</div>
				</div>
			)
		},
	},
	{
		name: 'packages',
		icon: 'fas fa-fw fa-tools',
		description: 'Display a list of my open-source npm packages',
		execute(app) {
			const { projectDataLoaded, projectData } = app.state
			if (!projectDataLoaded)
				return <>mohamed: project data could not be fetched</>
			return (
				<>
					{projectData.map(
						({ name, html_url, description }: any, key: number) => (
							<ListElement
								key={key}
								icon={'fab fa-fw fa-github-alt'}
								name={name}
								link={html_url}
								description={description}
							/>
						)
					)}
				</>
			)
		},
	},
	{
		name: 'links',
		icon: 'fas fa-fw fa-link',
		description: 'Get all my important links and socials',
		execute() {
			return (
				<>
					{links.map(({ icon, name, link, description }, key) => (
						<ListElement
							key={key}
							icon={icon}
							name={name}
							link={link}
							description={description}
						/>
					))}
				</>
			)
		},
	},
	{
		name: 'work experience',
		icon: 'fas fa-fw fa-briefcase',
		description: 'Display my work experience',
		execute() {
			return (
				<>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Development Tech Lead @Fiamane'}
						link={'https://pro.fiamane.com'}
						description={
							'- I led the development and maintenance of three mobile apps and a React admin dashboard, prioritizing performance, security, and user experience.'
						}
					/>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Back-end Developer @Fiamane'}
						link={'https://pro.fiamane.com'}
						description={
							'- Utilized Firebase, ECMAScript, WebSocket, Stripe, JavaScript, Node.js, Express.js, and MongoDB to enhance backend performance and deliver rich user experiences, while ensuring code coverage, bolstering security with Helmet, integrating Winston and Morgan for logging, and optimizing scalability with Docker.'
						}
					/>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Back-end Developer @Trenteneuf'}
						link={'#'}
						description={
							'- Led Medoka app backend development with Express.js, MongoDB, Firebase, and AWS S3, delivering a robust RESTful API, rigorous testing with Jest, and a streamlined CI workflow with Travis CI.'
						}
					/>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Back-end Developer @MEGALOGI'}
						link={'#'}
						description={
							'- Developed and maintained an efficient Laravel/OctoberCMS EbaySdk plugin, enabling seamless product synchronization, documented its functionality, engaged in agile practices, and led the innovative "Le Referencial" project for enhanced project management.'
						}
					/>
				</>
			)
		},
	},
	{
		name: 'side projects',
		icon: 'fas fa-fw fa-code',
		description: 'Display my personal projects',
		execute() {
			return (
				<>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Aitmiloud'}
						link={'https://aitmiloud.github.io/'}
						description={'Personal website'}
					/>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'Penako'}
						link={'https://penako.com/'}
						description={
							'Online platform for converting websites into mobile apps'
						}
					/>
					<ListElement
						icon={'fas fa-fw fa-code'}
						name={'SOD'}
						link={'https://sod.ma/'}
						description={
							'Freelance Services marketplace'
						}
					/>
				</>
			)
		},
	},
	{
		name: 'clear',
		icon: 'fas fa-fw fa-eraser',
		description: 'Clear terminal screen',
		execute(app) {
			app.setState({
				...app.state,
				record: [],
			})
		},
	},
]
const commands: Commands = new Map(rawCommands.map(cmd => [cmd.name, cmd]))

export default commands
