import React from 'react'
const github_username = 'aitmiloud'
const discord_usertag = 'geekait2#3386'
const discord_userid = '569257183821168671'
const email = 'mohamed@aitmiloud.com'

const projects = ['aitmiloud/cmi-node']

const links = [
	{
		name: 'GitHub',
		icon: 'fab fa-fw fa-github',
		link: 'https://github.com/aitmiloud',
		description: 'My profile on GitHub',
	},
	{
		name: 'Website',
		icon: 'fas fa-fw fa-coffee',
		link: 'https://aitmiloud.github.io/',
		description: 'Personal website',
	},
	{
		name: 'Discord',
		link: `https://discord.com/users/${discord_userid}`,
		icon: 'fab fa-fw fa-discord',
		description: `${discord_usertag} | Add me as friend!`,
	},
	{
		name: 'E-mail',
		icon: 'fas fa-fw fa-at',
		link: `mailto:${email}`,
		description: 'Lets get in touch!',
	},
]

const info = (
	<>
		<p>Greetings! I'm a seasoned <span className="highlighted">Senior Backend Developer</span> with a diverse skill set.</p>
		<p>
			I bring extensive experience in <span className="highlighted">Node.js</span> and <span className="highlighted">Laravel</span> to the table, having worked on numerous projects that showcase my proficiency in these technologies.
		</p>
		<p>
		On the front-end, I've dedicated several years to professional development, focusing on <span className="highlighted">Vue.js</span> and <span className="highlighted">Nuxt.js.</span> This dedication has allowed me to craft engaging and user-friendly interfaces.
		</p>
		<p>
		Beyond that, I'm proficient in Docker, SQL, NoSQL databases, AWS, RESTful and GraphQL APIs, Git, and testing frameworks like Jest and Mocha.

Let's build something great together! 🚀
		</p>
	</>
)

export {
	github_username,
	discord_usertag,
	discord_userid,
	email,
	projects,
	links,
	info,
}
